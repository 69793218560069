import { darken, lighten } from 'polished'
import styled, { keyframes } from 'styled-components'

import Merce404Mobile from '../../images/404-img.png'
import Merce404 from '../../images/404-img.webp'
import bigode from '../../images/le-bigode.svg'
import { device } from '../../styles/devices'

export const fadeOut = keyframes`
  100% {
  opacity: 100%;
  }
  0% {
    opacity: 0;
  }
`

export const ImgGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 7px;
  align-items: center;

  max-width: 1280px;

  margin: 4rem auto;
  cursor: pointer;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 160px;
    height: auto;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }

  img:hover {
    filter: none;
    -webkit-filter: grayscale(0);
  }

  @media (min-width: 600px) {
    img {
      width: 200px;
    }
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 800px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1010px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (min-width: 1240px) {
    grid-template-columns: repeat(6, 1fr);
  }
`

export const Content = styled.div`
  padding: 24px 0;
  @media all and ${device.laptopXS} {
    padding: 0;
  }

  #btn-scroll {
    animation: ${fadeOut} ease 0s;
    transition: opacity 0.5s cubic-bezier(0.6, -0.28, 0.74, 0.05) 0s;
  }
`

export const Title = styled.div`
  margin-top: 0;
  text-align: center;
  padding-top: 0;
  &:before {
    content: url(${bigode});
    height: 40px;
    width: 40px;
  }
  h4 {
    font-family: 'DonaAltBold';
    color: #db0047;
    font-size: 2.3rem;
    margin-top: 7px;
  }

  p {
    font-family: 'DonaAltRegular';
    color: #666666;
    font-size: 1.4rem;
    margin-bottom: 48px;
  }

  @media all and ${device.laptopXS} {
    margin-top: 14px;
    padding-top: 40px;
  }
`

export const HowItWorks = styled.ul`
  display: grid;
  list-style: none;
  margin: 0 auto;
  max-width: 1280px;

  @media all and ${device.laptopXS} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 52px;
  }
`

export const HowItWorksItem = styled.li`
  display: flex;
  padding: 0 16px;
  margin-bottom: 32px;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f5f5f5;
    color: #db0047;
    font-size: 3rem;
    border-radius: 50%;
    padding: 15px 24px;
    height: 75px;
    width: 75px;
    margin-right: 16px;
    font-family: DonaAltBold;
  }

  label {
    font-size: 1rem;
    font-family: DonaAltBold;
    color: #311659;
    text-align: left;
    margin: 0 auto;
    display: block;
    padding-top: 12px;

    br {
      display: none;
    }
  }

  span {
    font-size: 0.8rem;
    color: #666666;
    line-height: 1.4;
    text-align: left;
    margin: 0 auto;
  }

  @media all and ${device.tablet} {
    p {
      margin-top: -22px;
    }

    label {
      padding-top: 0;
    }

    span {
      font-size: 1rem;
    }
  }

  @media all and ${device.laptopXS} {
    padding: 0 40px;
    text-align: center;
    display: block;
    margin-bottom: 0;

    p {
      margin: 0 auto;
      width: 100px;
      padding: 20px 28px 12px 26px;
      height: 100px;
    }

    label {
      text-align: center;
      margin: 16px auto;

      br {
        display: block;
      }
    }
    span {
      text-align: center;
      font-size: 0.8rem;
    }
  }

  @media all and ${device.laptop} {
    padding: 0 35px;

    label {
      text-align: center;
      margin: 16px auto;
      font-size: 1.4rem;

      br {
        display: block;
      }
    }
    span {
      text-align: center;
      font-size: 0.9rem;
    }
  }

  @media all and ${device.laptopM} {
    padding: 0 26px;
  }

  @media all and ${device.laptopN} {
    padding: 0 40px;
  }

  @media all and ${device.laptopL} {
    padding: 0 53px;
  }

  @media all and ${device.desktopXS} {
    label {
      font-size: 1.2rem;
      br {
        display: none;
      }
    }
    span {
      font-size: 1.1rem;
    }
  }
`
export const BePart = styled.div`
  background-color: #f5f5f5;
  margin: 0 auto;
  text-align: center;
  padding-top: 41px;

  &:before {
    content: url(${bigode});
    height: 40px;
    width: 40px;
  }

  h4 {
    color: #db0047;
    font-family: DonaAltBold;
    font-size: 1.7rem;
  }

  label {
    color: #311659;
    display: block;
    margin-top: 41px;
    font-family: DonaAltBold;
    font-size: 1.7rem;
  }

  span {
    color: #311659;
    display: block;
    font-family: DonaAltBold;
    font-size: 1.7rem;
  }
  @media all and ${device.laptopXS} {
    h4 {
      font-size: 2rem;
    }
    label {
      margin-top: 66px;
      font-size: 1.6rem;
    }
    span {
      font-size: 1.3rem;
    }
  }

  @media all and ${device.laptop} {
    label {
      font-size: 1.6rem;
    }
  }

  @media all and ${device.desktopXS} {
    label {
      font-size: 1.6rem;
    }
  }
`

export const Register = styled.div``

export const Form = styled.form`
  margin-top: 44px;
  input {
    display: block;
    margin: 20px auto;
    width: 80%;
    border-radius: 10px;
    border: none;
    padding: 12px 16px;
    font-size: 16px;

    &:focus {
      color: #db0047;
    }
  }

  button {
    background-color: #065fd4;
    border-radius: 30px;
    color: #fff;
    border: none;
    width: 80%;
    margin: 0 auto 97px auto;
    padding: 16px;
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      background-color: ${lighten(0.1, '#065fd4')};
    }

    &:active {
      background-color: ${darken(0.1, '#065fd4')};
    }
  }

  @media all and ${device.tablet} {
    input,
    button {
      width: 46%;
    }
  }

  @media all and ${device.laptopXS} {
    margin-top: 24px;
    input {
      width: 30%;
    }
    button {
      width: 30%;
      padding: 10px;
    }
  }

  @media all and ${device.laptop} {
    input {
      width: 28%;
      padding: 16px;
    }
    button {
      width: 28%;
      padding: 16px;
      font-size: 1rem;
    }
  }

  @media all and ${device.desktopXS} {
    input {
      width: 24%;
    }

    button {
      width: 24%;
      padding: 14px;
    }
  }
`

export const Policy = styled.div`
  padding-top: 0;
  margin: 0 auto;
  display: flex;

  @media all and ${device.tablet} {
    padding-top: 150px;
    width: 1136px;
  }

  @media all and ${device.laptopL} {
    width: 1280px;
  }

  @media all and ${device.desktopXS} {
    width: 1760px;
  }
`

export const MenuPolicy = styled.ul`
  display: none;

  @media all and ${device.laptopXS} {
    display: block;
    list-style: none;
    margin-right: 16px;
    width: 20%;
  }
`

export const MenuPolicyItem = styled.li`
  line-height: 2;

  a {
    text-decoration: none;
    font-size: 0.9rem;
    color: #db0047;
  }

  .active {
    font-weight: 700;
  }
`
export const LegalText = styled.div`
  width: 100%;
  margin-bottom: 40px;
  padding: 23px;

  iframe {
    height: 70vh;
  }
`

export const Button = styled.div`
  text-align: center;
  position: fixed;
  bottom: 2rem;
  width: 320px;
  margin-left: calc(50% - 160px);
  z-index: 4;

  label {
    border: none;
    background-color: #065fd4;
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    line-height: 2.5;
    border-radius: 30px;
    padding: 14px 70px;
  }

  &:hover {
    background-color: ${lighten(0.1, '#065fd4')};
  }

  &:active {
    background-color: ${darken(0.1, '#065fd4')};
  }

  @media all and ${device.laptopXS} {
    display: none;
  }
`
export const Success = styled.div`
  padding: 90px 0 160px 0;
  label {
    margin-top: 0;
  }
`

export const ButtonSecondary = styled(Button)`
  position: relative;
  top: 0;
  margin-top: 32px;
  margin-bottom: 100px;

  label {
    background-color: #db0047;
    color: #fff;
    cursor: pointer;
    padding: 14px 24px;
    font-size: 16px;

    &:hover {
      background-color: #e54161;
      color: #fff;
    }
  }

  @media all and ${device.laptopXS} {
    display: block;
    text-align: left;
    font-size: 0.9rem;
    margin-top: 60px;
  }
`

export const TextError = styled.h2`
  margin-top: 24px;
  color: #db0047;
  font-family: DonaAltBold;
  text-align: center;
  br {
    display: none;
  }

  @media all and ${device.laptopXS} {
    text-align: left;
    font-size: 2rem;
    line-height: 1.4;

    br {
      display: block;
    }
  }
`
export const Subtitle = styled.p`
  color: #666666;
  font-family: DonaAltRegular;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 32px;

  br {
    display: none;
  }

  @media all and ${device.laptopXS} {
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.5;

    br {
      display: block;
    }
  }
`

export const ErrorPage = styled.div`
  margin: 0 16px;
  display: block;
  text-align: center;

  img {
    margin-top: 140px;
    width: 100%;
  }

  @media all and ${device.laptopXS} {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 40%;
      margin-top: 140px;
    }
  }
`

export const Image404 = styled.div`
  background-image: url(${Merce404});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 320px;
  width: 100%;
  margin: 73px auto 0 auto;

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      background-image: url(${Merce404Mobile});
    }
  }

  @media all and ${device.tablet} {
    width: 40%;
  }

  @media all and ${device.laptopXS} {
    width: 15%;
    height: 240px;
    margin: 159px 0 0 0;
  }

  @media all and ${device.laptop} {
    width: 18%;
  }

  @media all and ${device.desktopXS} {
    width: 12%;
    height: 221px;
  }
`

export const Content404 = styled.div`
  a {
    text-decoration: none;
  }

  @media all and ${device.laptopXS} {
    margin-top: 210px;
    margin-left: 42px;
  }
`
